import { ReactElement, useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import AnnualServiceService from 'reducers/services/annualServicesService'
import { RootState } from 'Store'
import { setSortingBy } from 'reducers/metrics'
import history from 'customHistory'
import { updateMissingGovs } from 'reducers/missingGov'
import { updateMissingPagines } from 'reducers/missingPagine'
import {
  updateYearsList, updateAvailableDates, updateAvailableDates2,
  updateExcludedDates, updateDisplayDatePicker,
} from 'reducers/annualServices'
import Grid from '@mui/material/Grid'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import customButton from 'common/CustomTheme'
import LoadingButton from '@mui/lab/LoadingButton'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Fade from '@mui/material/Fade'
import PathNames from 'common/PathNames'
import { Module } from 'reducers/types'
import DatesList from './DatesList'
import PickerDates from './PickerDates'
import PickerRange from './PickerRange'
import './stylesheets/datepicker.scss'

export default function CustomDatePicker(): ReactElement {
  const dispatch = useDispatch()
  const { activeStation, mode } = useSelector((state: RootState) => state.stations)
  const {
    yearsList, selectedDates, datesList, datesList2, datesList3, datesList4, availableDates, availableDates2,
    displayDatePicker,
  } = useSelector((state: RootState) => state.annualServices)

  const [selectionType, setSelectionType] = useState('days')
  const handleOnClick = () => ((mode === Module.GOV) ? history.push(PathNames.Metrics) : history.push(PathNames.Normes))

  useEffect(() => {
    dispatch(updateMissingGovs([]))
    dispatch(updateMissingPagines([]))
  }, [])

  useEffect(() => {
    dispatch(updateYearsList([]))
    dispatch(AnnualServiceService.getYearsList(activeStation.id))
  }, [])

  useEffect(() => {
    if (yearsList[0]) {
      const ids = {
        stationId: activeStation.id,
        yearId: yearsList[0].id,
      }
      dispatch(AnnualServiceService.getDatesList(ids))
    }
  }, [yearsList])

  useEffect(() => {
    if (yearsList[1]) {
      const ids = {
        stationId: activeStation.id,
        yearId: yearsList[1].id,
      }
      dispatch(AnnualServiceService.getDatesList2(ids))
    }
  }, [yearsList])

  useEffect(() => {
    if (yearsList[2]) {
      const ids = {
        stationId: activeStation.id,
        yearId: yearsList[2].id,
      }
      dispatch(AnnualServiceService.getDatesList3(ids))
    }
  }, [yearsList])

  useEffect(() => {
    if (yearsList[3]) {
      const ids = {
        stationId: activeStation.id,
        yearId: yearsList[3].id,
      }
      dispatch(AnnualServiceService.getDatesList4(ids))
    }
  }, [yearsList])

  useEffect(() => {
    if (datesList[0]) {
      const array1 = datesList.concat(datesList2, datesList3, datesList4).map(item => (
        new Date(item.date)
      ))
      const array2 = datesList.concat(datesList2, datesList3, datesList4).map(item => (
        item.date
      ))
      dispatch(updateAvailableDates(array1))
      dispatch(updateAvailableDates2(array2))
      dispatch(updateDisplayDatePicker(true))
    }
  }, [datesList, datesList2, datesList3, datesList4])

  useEffect(() => {
    if (availableDates2[0]) {
      let array = []
      let currentDate = new Date(availableDates2[0])
      const endDate = new Date(availableDates2[availableDates2.length - 1])
      while (currentDate <= endDate) {
        array = [...array, format(new Date(currentDate), 'yyyy-MM-dd')]
        const nextDate = addDays(currentDate, 1)
        currentDate = nextDate
      }
      const array2 = array.filter(date => !availableDates2.includes(date))
      const excludeDatesArray = array2.map(date => (
        new Date(date)
      ))
      dispatch(updateExcludedDates(excludeDatesArray))
    }
  }, [availableDates2])

  useEffect(() => {
    dispatch(setSortingBy([]))
  }, [])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={!displayDatePicker}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {availableDates.length !== 0 && (
      <>
        <Grid
          container
          sx={{
            justifyContent: 'center', maxWidth: '900px',
          }}
        >
          <Paper sx={{
            justifyContent: 'center', p: '20px', width: '100%', boxShadow: 'none',
          }}
          >
            <Fade timeout={400} in mountOnEnter unmountOnExit>
              <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Stack>
                  <Stack sx={{ minHeight: '320px' }}>
                    {(selectionType === 'range') ? <PickerRange /> : <PickerDates />}
                  </Stack>
                  <Box>
                    <Grid container item>
                      <Grid item container xs={12} sx={{ marginLeft: '22px' }}>
                        <RadioGroup
                          row
                          aria-labelledby="selection-options"
                          name="selection-options"
                          defaultValue="days"
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onClick={(e: any) => { setSelectionType(e.target.value) }}
                        >
                          <FormControlLabel
                            sx={{
                              '& .MuiTypography-root': {
                                fontFamily: 'Avenir',
                                fontSize: '14px',
                                fontWeight: '900',
                                color: '#44547F',
                              },
                            }}
                            value="days"
                            control={<Radio />}
                            label={terms.DatePicker.radioLabelDaySelection}
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiTypography-root': {
                                fontFamily: 'Avenir',
                                fontSize: '14px',
                                fontWeight: '900',
                                color: '#44547F',
                              },
                            }}
                            value="range"
                            control={<Radio />}
                            label={terms.DatePicker.radioLabelPeriodSelection}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <DatesList />
              </Stack>
            </Fade>
          </Paper>
        </Grid>
        <Grid container item xs={12} sx={{ justifyContent: 'center' }}>
          <ThemeProvider theme={customButton}>
            <LoadingButton
              variant="outlined"
              onClick={handleOnClick}
              disableElevation
              disabled={(selectedDates.length === 0)}
              endIcon={<ArrowForwardIcon />}
              sx={{
                width: '400px',
                paddingY: '15px',
                borderRadius: '30px',
                borderColor: '#61B8FA',
                color: 'white',
                background: '#61B8FA',
                marginTop: '56px',
                marginBottom: '3rem',
              }}
            >
              {terms.DatePicker.validateBT}
            </LoadingButton>
          </ThemeProvider>
        </Grid>
      </>
      )}
    </>
  )
}
