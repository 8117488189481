/* eslint-disable camelcase */
import { AppDispatch } from 'Store'
import { ResponseError } from 'types'

export enum Module {
  GOV = 'GOV',
  RE = 'RE',
}

export type DispatchFunction = (dispatch: AppDispatch) => void;
export interface AppState {
  error: null | undefined | ResponseError;
  fullscreen: boolean;
}
export interface Station {
  id: string;
  id_gaia: string;
  nom_gare: string;
  abv_traction: string;
  code_CI: string;
  code_CH: string;
  image: string;
  dans_ogconception: boolean;
  dans_ogtr: boolean;
  est_favorite: boolean;
  est_config: boolean;
  est_admin: boolean;
}
export interface StationsState {
  stationsList: Array<Station>;
  activeStation: Station | undefined;
  loading: boolean;
  error: ResponseError | undefined;
  saveStatus: boolean;
  requiredConfig: boolean;
  ordering: string;
  search: string;
  monochrome: boolean;
  mode: Module;
}

export interface TracksState {
  tracksList: Array<string>;
  loading: boolean;
  error: ResponseError | undefined;
  updateStatus: boolean;
}

export interface Groix {
  id: string;
  uuid_fichier: string;
  nom_fichier: string;
  date_creation: string;
  utilisateur: string;
  statut: string;
}

export interface UploadedItem {
  id: string;
  nom_fichier: string;
}

export interface GroixState {
  groixList: Array<Groix>;
  newGroix: string | undefined;
  loading: boolean;
  error: ResponseError | undefined;
  uploadedList: Array<UploadedItem>;
  uploadedItem: UploadedItem;
  groix: object;
}

export type GetParams = {
  ordering?: string;
  page?: number;
  search?: string;
};

export type AnnualServices = {
  id: string;
  annee: number | string | undefined;
};

export interface AnnualServicesState {
  yearsList: Array<AnnualServices>;
  datesList: Array<AnnualServices>;
  datesList2: Array<AnnualServices>;
  datesList3: Array<AnnualServices>;
  datesList4: Array<AnnualServices>;
  availableDates: Array<Date>;
  availableDates2: Array<Date>;
  minDate: string;
  maxDate: string;
  activatedDates: Array<string>;
  selectedDates: Array<string>;
  confirmedDates: Array<string>;
  excludedDates: Array<Date>;
  loading: boolean;
  error: ResponseError | undefined;
  displayDatePicker: boolean;
  monthToShow?: Date;
}

export type QueryParams = any;

export type PatchParams = {
  stationId: string;
  formData: FormData;
};

export interface Gov {
  id: string;
  type: string;
  etat: string;
  nom_fichier: string | null;
  fichier_groix: string | null;
  statut: string;
}

export type Filters = {
  balance_type?: string[];
  circulation_type?: string[];
  multi_time_range?: string[];
  track?: string[];
  equipment?: string[];
  train_number?: string[];
  train_number_range?: string[];
  ori_dest?: string[];
}

export type FiltersDelay = {
  operation: string;
  value1: number;
  value2: number;
}

export type TrainNumberRange = {
  index: number;
  start: string;
  end: string;
}

export type MissingGovsParams = {
  ids: string | string[];
  comparaisontype: string;
}

export type Pagine = {
  id: string;
  statut: string;
}

export type GovsList = {
  govs: Gov[];
  comparaisonType: string;
}

export type TrainNumberField = {
  index: number;
  operator: string;
  trainNumber: string;
}

export type OriginDestination = {
  index: number;
  origin: string;
  destination: string;
}

export type TimeRange = {
  index: number;
  startTime: Date;
  endTime: Date;
}

export type Equipment = {
  id?: string;
  code: number | undefined;
  libelle? : string | null;
}

export interface EquipmentCodes {
  equipmentsList: Array <Equipment>;
  loading: boolean;
  error: ResponseError | undefined;
  updateStatus: boolean;
}

export type ErrorMessage = {
  message: {
    detail: string;
  };
  status: number;
}
