/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import annualServiceService from 'reducers/services/annualServicesService'
import { AnnualServicesState } from './types'

const initialState: AnnualServicesState = {
  yearsList: [],
  datesList: [],
  datesList2: [],
  datesList3: [],
  datesList4: [],
  availableDates: [],
  availableDates2: [],
  minDate: '',
  maxDate: '',
  activatedDates: [],
  selectedDates: [],
  confirmedDates: [],
  excludedDates: [],
  loading: false,
  error: undefined,
  displayDatePicker: false,
}

export const annualServiceSlice = createSlice({
  name: 'annual-service',
  initialState,

  reducers: {
    updateYearsList: (state, action: PayloadAction<Array<any>>) => {
      state.yearsList = action.payload
    },
    updateDatesList: (state, action: PayloadAction<Array<any>>) => {
      state.datesList = action.payload
      state.datesList2 = action.payload
      state.datesList3 = action.payload
      state.datesList4 = action.payload
    },
    updateAvailableDates: (state, action: PayloadAction<Array<Date>>) => {
      state.availableDates = action.payload
    },
    updateAvailableDates2: (state, action: PayloadAction<Array<Date>>) => {
      state.availableDates2 = action.payload
    },
    updateExcludedDates: (state, action: PayloadAction<Array<Date>>) => {
      state.excludedDates = action.payload
    },
    updateSelectedDates: (state, action: PayloadAction<Array<string>>) => {
      state.selectedDates = action.payload
    },
    updateConfirmedDates: (state, action: PayloadAction<Array<string>>) => {
      state.confirmedDates = action.payload
    },
    updateDisplayDatePicker: (state, action: PayloadAction<boolean>) => {
      state.displayDatePicker = action.payload
    },
    updateMonthToShow: (state, action: PayloadAction<Date>) => {
      state.monthToShow = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(annualServiceService.getYearsList.pending, state => {
      state.loading = true
    })
    builder.addCase(annualServiceService.getYearsList.fulfilled, (state, action) => {
      state.yearsList = action.payload
      state.loading = false
    })
    builder.addCase(annualServiceService.getYearsList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(annualServiceService.getDatesList.pending, state => {
      state.loading = true
    })
    builder.addCase(annualServiceService.getDatesList.fulfilled, (state, action) => {
      state.datesList = action.payload
      state.loading = false
    })
    builder.addCase(annualServiceService.getDatesList2.fulfilled, (state, action) => {
      state.datesList2 = action.payload
      state.loading = false
    })
    builder.addCase(annualServiceService.getDatesList3.fulfilled, (state, action) => {
      state.datesList3 = action.payload
      state.loading = false
    })
    builder.addCase(annualServiceService.getDatesList4.fulfilled, (state, action) => {
      state.datesList4 = action.payload
      state.loading = false
    })
    builder.addCase(annualServiceService.getDatesList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  updateYearsList, updateDatesList, updateAvailableDates, updateAvailableDates2, updateExcludedDates,
  updateSelectedDates, updateConfirmedDates, updateDisplayDatePicker, updateMonthToShow,
} = annualServiceSlice.actions

export default annualServiceSlice.reducer
